import React from 'react';
import { navigate } from 'gatsby';

class Download extends React.Component {
  componentDidMount() {
    const userAgent =
      typeof window !== 'undefined' && window.navigator.userAgent;
    const platform = typeof window !== 'undefined' && window.navigator.platform;
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];

    if (iosPlatforms.indexOf(platform) !== -1) {
      window.location.replace(
        `itms://itunes.apple.com/th/app/pumpkin/id1392855143?mt=8`
      );
    } else if (/Android/.test(userAgent)) {
      window.location.replace(`market://details?id=com.mi.pumpkin`);
    } else {
      navigate('#downloadSection');
    }
  }

  render() {
    return null;
  }
}

export default Download;
